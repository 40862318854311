@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Inter, system-ui, sans-serif;
  }
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.content-placeholder {
  animation-name: shimmer;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  background-image: linear-gradient(
    to right,
    #f0ece6 0%,
    #e8e0d5 20%,
    #f0ece6 40%,
    #f0ece6 100%
  );
  background-size: 800px 104px;
}

.is-dark-mode .content-placeholder {
  background-image: linear-gradient(
    to right,
    #3a3a3a 0%,
    #4a4a4a 20%,
    #3a3a3a 40%,
    #3a3a3a 100%
  );
  background-size: 800px 104px;
}

/* Light mode scrollbar */
::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  background: rgba(15, 23, 36, 0.08);
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(15, 23, 36, 0.16);
}

/* Dark mode scrollbar */
.is-dark-mode ::-webkit-scrollbar {
  width: 14px;
}

.is-dark-mode ::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2); 
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 8px;
}

.is-dark-mode ::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3); 
}

/* Light mode */
.content-gradient-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 32px;
  background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0));
  pointer-events: none;
  z-index: 10;
}

.content-gradient-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
  pointer-events: none;
  z-index: 10;
}

/* Dark mode */
.is-dark-mode .content-gradient-top {
  background: linear-gradient(to bottom, #151515, rgba(21, 21, 21, 0));
}

.is-dark-mode .content-gradient-bottom {
  background: linear-gradient(to top, #151515, rgba(21, 21, 21, 0));
}

.chat-content p {
  margin-bottom: 1rem;
}

.chat-content p:last-child {
  margin-bottom: 0;
}

.chat-content strong {
  font-weight: bold;
}

.chat-content em {
  font-style: italic;
}

.chat-content ul {
  list-style-type: disc;
  padding-left: 1.25rem;
  margin-bottom: 1rem;
}

.chat-content li {
  margin-bottom: 0.5rem;
}

.chat-content li:last-child {
  margin-bottom: 0;
}

.chat-content a {
  color: rgb(251, 146, 60);
  text-decoration: underline;
}

.chat-content a:hover {
  color: rgb(249, 115, 22);
}

.is-dark-mode .chat-content a {
  color: rgb(251, 146, 60);
}

.is-dark-mode .chat-content a:hover {
  color: rgb(253, 186, 116);
} 
